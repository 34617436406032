// committees pages

.sidebar-sponsors {
  @extend .nolist;
  @include margin-top(24px);
  text-align: center;

  li {
    @include margin-bottom(24px);
  }

  img {
    max-width: 150px;
    height: auto;
  }
}

.sponsors-tier {
  @include margin-bottom(48px);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  
  .sponsors-tier--item {
    text-align: center;
  }
}
  
.sponsors-tier--item {

  h4 {
    @include margin(18px);
    @include font-size(16px);
    
    @include media-breakpoint-up(lg){
      @include margin(24px);
      @include font-size(25px);
    }
  }
}
 
.sponsors-tier--logo {

  .sponsors-tier--item img {
    max-width: 100px;
    @include margin(10px);

    @include media-breakpoint-up(lg){
      max-width: 130px;
    }
  }
}

.sponsors-tier--logo:nth-of-type(1) {

  .sponsors-tier--item img {
    max-width: 300px;

    @include media-breakpoint-up(lg){
      max-width: 360px;
    }
  }
}

.sponsors-tier--logo:nth-of-type(2) {

  .sponsors-tier--item img {
    max-width: 140px;
    @include margin(16px);

    @include media-breakpoint-up(lg){
      max-width: 180px;
    }
  }
}

.sponsors-tier--1 {

  .sponsors-tier--item img {
    max-width: 300px !important;

    @include media-breakpoint-up(lg){
      max-width: 360px !important;
    }
  }
}

.sponsors-tier--2 {

  .sponsors-tier--item img {
    max-width: 140px !important;
    @include margin(16px);

    @include media-breakpoint-up(lg){
      max-width: 180px !important;
    }
  }
}

.sponsors-tier--3 {

  .sponsors-tier--item img {
    max-width: 110px !important;
    @include margin(14px);

    @include media-breakpoint-up(lg){
      max-width: 150px !important;
    }
  }
}
