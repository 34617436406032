// navburger
.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;
  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  // margin: 0;
  overflow: visible;

  &:active, 
  &:focus {
  outline: 0;
  border: 0;
  }
  
  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      // opacity: $hamburger-hover-opacity;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

/*
 * Squeeze
 */
.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.1s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.14s ease, opacity 0.1s ease;
    }

    &::after {
      transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

// preheader
.preheader {
  padding: 0 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: hidden;
  background-color: $black;
  font-family: $sans-serif;
  @include font-size(14px);
  color: $white;
  text-align: center;
  z-index: 999;

  .nav-preheader {
    // margin-right: 15px;
    position: relative;
    display: flex;

    @include media-breakpoint-up(lg){
      margin-left: 8.33333333vw;
    }

    #menu-preheader-navigation,
    #preheader-submenu {
      flex-flow: column nowrap;
      margin-bottom: -120px;
      text-align: left;
      @include transition(all 0.5s ease);
      
      @include media-breakpoint-up(lg){
        flex-flow: row nowrap;
      }

      &.showsub {
        margin-bottom: 0;
      }

      li {
        a {
          position: relative;
          margin-right: 20px;
          padding: 0 15px 0 0;
          display: inline-block;
          line-height: 40px;
          color: $white;
          white-space: nowrap;

          &:hover {
            color: $red;
          }

          $corner_btn_width: 10px;

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: calc(50% - (#{$corner_btn_width} / 2));
            width: $corner_btn_width;
            height: $corner_btn_width;

            // border-top: math.div($corner_btn_width, 2) solid $black;
            // border-left: math.div($corner_btn_width, 2) solid $black;
            // border-bottom: math.div($corner_btn_width, 2) solid $red;
            // border-right: math.div($corner_btn_width, 2) solid $red;
            border-top: ($corner_btn_width / 2) solid $red;
            border-right: ($corner_btn_width / 2) solid $red;
            border-bottom: ($corner_btn_width / 2) solid $black;
            border-left: ($corner_btn_width / 2) solid $black;
          }
        }
      }
    }

    #menu-preheader-navigation-more {
      // position: absolute;
      // right: 15px;
      // top:0;
      margin-right: 10px;


      @include media-breakpoint-up(lg){
        display: none;
      }

      #sub_burger {
        position: relative;
        display: block;
        width: 34px;
        height: 40px;
        color: $white;
        // font-weight: 700;
        // text-align: center;
        margin: 0;
        padding: 0;

  
$subburger-padding-x                       : 15px !default;
$subburger-padding-y                       : 15px !default;
$subburger-layer-width                     : 18px !default;
$subburger-layer-height                    : 1px !default;
$subburger-layer-spacing                   : 4px !default;
$subburger-layer-color                     : $white !default;
$subburger-layer-border-radius             : 4px !default;
$subburger-hover-opacity                   : 0.7 !default;
$subburger-hover-transition-duration       : 0.15s !default;
$subburger-hover-transition-timing-function: linear !default;


        .hamburger-box {
          width: $subburger-layer-width;
          height: ($subburger-layer-height * 3) + ($subburger-layer-spacing * 2);
          display: inline-block;
          position: relative;
        }

        .hamburger-inner {
          display: block;
          top: 50%;
          margin-top: $subburger-layer-height / -2;

          &,
          &::before,
          &::after {
            width: $subburger-layer-width;
            height: $subburger-layer-height;
            background-color: $subburger-layer-color;
            border-radius: $subburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
          }

          &::before,
          &::after {
            content: "";
            display: block;
          }

          &::before {
            top: ($subburger-layer-spacing + $subburger-layer-height) * -1;
          }

          &::after {
            bottom: ($subburger-layer-spacing + $subburger-layer-height) * -1;
          }
        }
      }
 
      ul#preheader-submenu {    

        li {

          a {

          }
        }
      }
    }
  }

  .social-preheader {
    text-align: center;
    margin-left: -44px; // navburger + 10px margin

    @include media-breakpoint-up(lg){
      margin-left: 0; // navburger + 10px margin
    }

    .social-icons {
      margin: 0;
      padding: 0;

      li {
        margin-top: 0;
        padding-top: 0;
      }
      
      a,
      .webicon {
        @include font-size(16px);
        line-height: 40px;
        color: $med-gray;

        &:hover {
          color: $red;
        }
      }
    }
  }

}

header.banner {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  height: $navigation-height-sm;
  background-color: $light-gray;
  box-shadow: 0 0 15px rgba($black,0.6);
  z-index: 998;
  @include transition(all 0.25s ease);
    
  @include media-breakpoint-up(xl){
    background-position: -10vw 0;
    height: $navigation-height;
  }

  @media (min-width: 1200px){
    background-position: -14vw 0;
  }

  @media (min-width: 1600px){
    background-position: -3vw 0;

  // @include media-breakpoint-up(xl){
    // background-position: calc( 100vw * 1/1920 * 144 * -1 ) 0;
    // 1920
    // 3vw = 57.6px
    // 58 of 300 = 19.33333%
    // 1200
    // 12vw = 144px
    // 144 of 300 = 48

    height: $navigation-height;
  }

  .container-fluid {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .brand {
    margin: 0 0 0 15px;
    width: 114px;
    height: 45px;
    text-indent: -999em;
    background-image: url(../images/aia-austin.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    z-index: 101;
    // @include transition(all 0.2s ease);
    
    @include media-breakpoint-up(xl) {
      margin-left: 8.33333333vw;
      width: 165px;
      height: 65px;
    }
  }

  // navburger
  .navbar-toggle {
    z-index: 999;
    // position: absolute;
    // left: 15px;
    // top: (($navigation-height-sm - 21px) / 2); // 21px = navburger height

    @include media-breakpoint-up(xl){
      display: none;
      // left: 25px;
      // top: calc((#{$navigation-height} - 21px)/2);
    }
  }
}

.nav-primary {
  display: none;
  position: absolute;
  top: $navigation-height-sm;
  left: 0;
  width: 100%;
  min-height: calc(100vh - #{$navigation-height-sm});
  z-index: 998;
  opacity: 0;
  @include transition(all 0.5s ease);

  @include media-breakpoint-up(xl){
    margin-right: 8.33333333vw;
    display: block;
    position: relative;
    top: unset;
    left: unset;
    width: auto;
    height: auto;
    min-height: unset;
    opacity: 1;
  }

  &:not(.is-active) {
    
    #menu-main-navigation {
      // display: none;
    }
  }

  &.show {
    display: flex;
    opacity: 1;
    background-color: $white;
    overflow-y: scroll;
    height: calc(100vh - #{$navigation-height-sm});
    z-index: 9999;
  }

  #menu-main-navigation {
    display: flex !important;
    flex-flow: column nowrap;
    padding-top: calc(#{$navigation-height-sm / 2});
    // min-height: 100vh !important;
    // height: 100vh !important;
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: auto;
    background-color: unset;
    z-index: 998;
    @include transition(all 0.5s ease);

    @include media-breakpoint-up(xl){
      padding: 0;
      opacity: 1;
      // display: flex !important;
      flex-flow: row nowrap;
      min-height: unset !important;
      height: auto !important;
      position: relative;
      width: auto;
      background-color: unset;
    }

    &.collapse:not(.show) {
      // display: unset!important;
    }

    // &.show,
    // &.collapsing, {
    //   display: flex;
    //   opacity: 1;
    //   position: fixed;
    //   height: 100vh;
    //   justify-content: center;
    //   align-items: center;
    //   flex-flow: column nowrap;
    //   z-index: 997;
    // }

    li {
      position: relative;
      margin: 0;
      padding: 0;
      text-align: left;
      box-shadow: inset 0 0 $green; // green bar
      @include transition(all .25s ease);

      a {
        padding: 0 15px;
        display: block;
        font-family: $sans-serif;
        @include font-size(24px);
        line-height: 1.618;
        font-weight: 700;
        color: $black;
        // text-transform: uppercase;
        letter-spacing: normal;
        @include transition(all .25s ease);

        // @include media-breakpoint-up(xl){
        //   padding: 0;
        //   line-height: $navigation-height-sm;
        // }

        &:hover {
          // color: rgba($white, 0.5);
          color: $med-gray;
        }

        @include media-breakpoint-down(lg){

          &.nogo {
            display: none;
            height: 0;
            overflow: hidden;
          }
        }

        &::after {
          content: '+';
          position: absolute;
          right: 15px;
        }

        @include media-breakpoint-up(xl){
          @include font-size(18px);
          @include padding(0 25px);
          line-height: $navigation-height;

          &::after {
            content: '';
            // position: absolute;
            right: 0;
          }
        }
      }

      &.cart-link {
        margin-left: 0;
        margin-right: 0;
        position: relative;

        a {
          @include font-size(18px);
        }

        .cart-count {
          position: absolute;
          top: 50%;
          right: 50%;
          width: 15px;
          height: 15px;
          font-size: 10px;
          line-height: 15px;
          font-family: $sans-serif;
          color: $white;
          text-align: center;
          background-color: $black;
          border-radius: 50%;
          transform: translate(20px, -15px);
        }
      }

      .sub-menu {
        margin:0;
        padding: 0;
        list-style: none;
        display: block;

        @include media-breakpoint-up(xl){
          display: none;
          min-width: 250px;
          position: absolute;
          left: 0; 
          height: 0;
          z-index: 999;
          opacity: 0; // http://jsfiddle.net/hashem/9dsGP/2/
          width: auto;
          background-color: $white;
          @include transition(all .25s ease);
          box-shadow: 0 0 10px rgba($black, 0.2);
        }

        li {
          margin: 0;
          padding: 0;
          
          @include media-breakpoint-up(xl){
            white-space: nowrap;
            text-align: left;

            &:hover {
              // background-color: $light-gray;
            }
          }

          a {
            @include font-size(16px);
            color: $black;
            position: relative;
            
              padding: 0;
              @include margin(10px 15px);
              @include padding(0);
              @include font-size(14px);
              color: $black;
              display: block;
              line-height: 1.3;

              @include media-breakpoint-up(xl){
                border-bottom: 1px solid $med-gray;
                @include margin(10px 25px);
                @include padding(10px 16px 10px 0);
              }
            
              &::after {
                position: absolute;
                right: 0;
                content: "\003e";
                color: $red;
                font-weight: 700;
                font-size: 18px;
                line-height: 1;
              }

            &:hover {
              color: $med-gray;
            }
          }

          &:last-of-type {

            a {
              border-bottom: 0;
            }
          }
        }
      } // END .sub-menu

      &.search-link {
        a {

        }
      }

      @include media-breakpoint-up(xl){
        &:hover .sub-menu {
          display: block;
          opacity: 1;
          height: auto;

          li {
            // background-color: transparent;
            box-shadow: unset;
          }
        }
      }

      &:last-of-type {

        @include media-breakpoint-up(xl){
        
          .sub-menu {
            left: auto;
            right: 0;
          }
        }
      }

      &:hover {
      // &.active {

        a {
          color: $med-gray;
        }
      }
    }
  }
}

// searchbar
.search-link {

  #searchbar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -999rem;
    background-color: $white;
    // width: 800px;
    opacity: 0;
    @include transition(opacity 0.5s ease);

    @include media-breakpoint-up(lg){
      height: $navigation-height;
      width: 800px;
      background-color: $light-gray;
    }

    &.active {
      right: 15px;
      left: 15px;
      opacity: 1;
      
      @include media-breakpoint-up(lg){
        left: unset;
        right: 0;
      }
    }

    #button-search {
      // @extend .visually-hidden;
    }
  }
}