// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .visually-hidden;
  @extend .visually-hidden-focusable;
}


/////////////////////////////////////////////////
// login, registration, password pages
// cheat sheet: https://premium.wpmudev.org/blog/customize-login-page/
/////////////////////////////////////////////////
body.login.wp-core-ui {
  background-color: $white;
  
  & .message, 
  & .success,
  #login_error {
    border: 1px solid $red;
    padding: 12px;
    margin-left: 0;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
  }

  #login_error {
    border: 1px solid $red;
  }

  & .button, 
  & .button-secondary {
    color: $red;
    border-color: $red;
    background: #f3f5f6;
    // vertical-align: top;
  }


  #login {
    width: 380px;
    max-width: 95%;
    padding: 100px 0;
  }
    
  #loginform,
  #registerform,
  #lostpasswordform,
  #resetpassform {
    box-shadow: none;
    background-color: $white;
    color: $black;
    border: none;
    border-bottom: 0 solid $red;
    padding: 0;
    margin: auto;
    position: relative;
    
    >p {
      margin-top: 15px;
    }
    
    input[type=text], input[type=date], input[type=email], input[type=password] {
      font-family: $font-family-sans-serif;
      font-size: 16px;
      color: $black;
      border-color: $black;
      background-color: transparent;
      text-shadow: none;
      box-shadow: none;
      padding: 5px 10px;
      border-radius: 0;      
    }
    
    label {
      font-family: $font-family-sans-serif;
      font-size: 16px;
      color: $black;
    }
  }

  // remember me
  .pw-weak,
  & .forgetmenot {
    // @extend .custom-control; 
    // @extend .custom-checkbox;

    input[type=checkbox] {
      // @extend .custom-control-input;
    }

    label {
      // @extend .custom-control-label;

      &:before {
        color: #fff;
        border-color: $red;
        background-color: $red;
        border-radius: .25rem;
        position: absolute;
        top: .25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        content: "";
      }

      &:after {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
        background: no-repeat 50%/50% 50%;
      }
    }
  }
  
  // um, submit
  #wp-submit {
    // @include debuttonify;
    @extend .btn;
    @extend .btn-lg;
    @extend .btn-hlw;
    // @extend .btn-primary;
    // @extend .shadow;
    // @extend .mb-2;
    
    text-shadow: none;
    box-shadow: none;
    margin: 20px auto;
    border: 1px solid $black;
    display: block;
    width: 100%;
    height: auto;
    padding: 14px 20px 11px;
  }

  .wp-social-login-provider-facebook::after { content: 'Sign in with Facebook'; }
  .wp-social-login-provider-google::after { content: 'Sign in with Google'; }
  .wp-social-login-provider-linkedin::after { content: 'Sign in with LinkedIn'; }
  .wp-social-login-connect-with { display: none; }
  
  .wp-social-login-widget {
    margin-bottom: 25px;
  }
  
  .register__modeDivider {
    .titled {
      top: -21px;
      font-size: 14px;
    }
  }
  
  // Forgot password and Registration nav
  #nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0;
    border-top: 1px solid $gray;
    padding-top: 20px;
    
    a {
      font-size: 14px;
      font-family: $font-stack;
      font-weight: bold;
      margin: 0 10px;
      
      &:hover {
        color: $red;
      }
    }
  }
  
  #backtoblog { display: none; }
  .privacy-policy-page-link { display: none; }
}

#wp-auth-check-wrap #wp-auth-check {
  // position: fixed;
  // left: 50%;
  // overflow: hidden;
  // top: 40px;
  // bottom: 20px;
  // max-height: 415px;
  // width: 380px;
  // margin: 0 0 0 -190px;
  // padding: 30px 0 0;
  background-color: transparent;
  // z-index: 1000011;
  box-shadow: unset;
}
