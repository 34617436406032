// programs pages
.drawer-nav {
  ul {
    @extend .nolist;
    display: flex;
    flex-flow: row wrap;

    li {
      @include margin(0 24px 16px 0);
      @include padding(16px 40px 16px 0);
      @include transition(all 0.2s ease);

      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 45%;

      @include media-breakpoint-up(lg){
        width: auto;
        @include margin-right(24px);
        @include padding(16px 40px 16px 0);

      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        // font-weight: 700;
        color: $red;
      }

      &::after {
        position: absolute;
        // top: 50%;
        right: 1rem;
        // transform: translateX(-10px);
        transform: rotate(90deg);
        
        content: "\003e";
        color: $red;
        font-weight: 700;
        font-size: 24px;
        line-height: 1;
        @include transition(all 0.2s ease);
      }

      &.active::after {
        transform: rotate(-90deg);
      }
    }
  }

  &.bordered {
    ul {
      li {
        border-top: 1px solid $black;
        border-bottom: 3px solid $black;
      }
    }
  }
}

.drawer-content {
  display: none;
  height: 0;
  opacity: 0;
  @include padding(24px 0);
  overflow: hidden;
  border-bottom: 1px solid $gray;
  @include transition(all 0.5s ease);

  &.active {
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    opacity: 1;

    @include media-breakpoint-up(lg){
      flex-flow: row nowrap;
    }

  }

  .img-holder {
    margin-right: 16px;
    margin-bottom: 16px;
    min-width: 200px;
    max-width: 200px;
  }
}

// award winners list
.sb-winner-list {
  @extend .nolist;

  li {
    margin: 0 0 5px;
    padding: 0 0 5px;
    border-bottom: 1px solid $med-gray;

    a {
      color: $black;

      &:hover {
        color: $red;
      }
    }
  }
}