footer.content-info {
  @include margin-top(48px);
  @include padding-top(60px);
  @include padding-bottom(16px);
  color: $black;
  background-color: $light-gray;
  box-shadow: 0 0 10px rgba($black,0.4);

  h2.footer-logo {
    @include margin-bottom(24px);
    @include font-size(24px);
  }

  .legal {
    @include margin-top(40px);
    margin-bottom: 0;
    @include font-size(14px);
    text-align: center;
    color: $gray;
    
    @include media-breakpoint-up(lg){
      text-align: right;
    }
  }

  .footer-signup {
    @include margin(25px auto 60px);
      
    @include media-breakpoint-up(md){
      width: 400px;
    }
  
    @include media-breakpoint-up(lg){
      margin-top: 0;
      width: auto;
    }

    form {
      width: 100%;
    }

    .mc4wp-form-fields {
      display: flex;
    }

    .mc4wp-response {
      @include margin-top(15px);
      @include padding-left(12px);

      * {
        color: $red;
      }
    }

    h5 {
      @include margin-bottom(20px);
      @include font-size(14px);
      color: $black;
      line-height: 1.1;
      font-weight: 400;
      font-family: $serif;
      text-transform: uppercase;
    }

    input[type=email] {
      border-radius: 0;
      border-color: $red;
      background-color: transparent;
      color: $black;
    }
  }

  .nav-footer {

    ul#menu-footer-navigation {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;

      li {
        margin: 0 24px 24px 0;
        padding: 0;

        a {
          color: $red;
          // font-family: $sans-serif;
          text-transform: uppercase;
          @include font-size(18px);

          &:hover {
            color: $black;
          }
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}