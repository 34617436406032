.acf-map {
   @include margin(32px 0);
   width: 100%;
   height: 400px;
   border: 1px solid $light-gray;

   .gm-style .gm-style-iw-c {
     // position: absolute;
     // top: 0;
     // left: 0;
     // -webkit-transform: translate3d(-50%,-100%,0);
     // -moz-transform: translate3d(-50%,-100%,0);
     // transform: translate3d(-50%,-100%,0);
     // background-color: white;
     border-radius: 0 !important;
     padding: 0 !important;
     overflow: visible;
     // box-shadow: 0 2px 7px 1px rgba(0,0,0,.3);
   }

   .gm-style .gm-style-iw-d {
      // -webkit-box-sizing: border-box;
      // -moz-box-sizing: border-box;
      // box-sizing: border-box;
      overflow: hidden !important;
   }

   .gm-ui-hover-effect {
      background-color: $white !important;
      display: block !important;
      // border: 0px;
      // margin: 0px;
      // padding: 0px;
      // text-transform: none;
      // appearance: none;
      // position: absolute;
      // cursor: pointer;
      // user-select: none;
      top: -15px !important;
      right: -15px !important;
      // width: 30px;
      // height: 30px;
      opacity: 1;

      &:hover {

      }
   }
}

// Fixes potential theme css conflict.
.acf-map {

   .img-holder {
      width: 250px;

      @include media-breakpoint-up(lg){
         width: 300px;
      }

      img {
        max-width: inherit !important;
      }
   }
}

.ht-big-img {
   --bs-aspect-ratio:65%;
   
   img {
     width: 100%!important;
     height: 100%!important;
     object-fit: cover;
     object-position: center center;
   }
}

.ht-video,
.ht-gallery {
   @include margin(0 0 32px);
   padding: 0;
}

.ht-gallery {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 16px;

   @include media-breakpoint-up(md){
      grid-template-columns: repeat(4, 1fr);
   }

   @include media-breakpoint-up(lg){
      grid-template-columns: repeat(5, 1fr);
   }

   [data-bs-target] {
      position: relative;
      box-sizing: content-box;
      flex: unset;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      background-color: unset;
      background-clip: padding-box;
      border: 0;
      border: unset;
      opacity: 1;
      transition: all .5s ease;

      &:hover {
         cursor: pointer;
         opacity: 0.5;
      }
   }
}

.arch-list {
   list-style: none;
   margin: 0;
   padding: 0;
   display: flex;
   flex-flow: column nowrap;

   @include media-breakpoint-up(lg){
      flex-flow: row wrap;
   }

   li {
      @include margin-right(32px);
      @include margin-bottom(16px);

      a {
         color: $black;

         &:hover {
            color: $red;
         }
      }
   }
}