
// post filtering on archive pages
.list-filters {
  display: flex;
  
  .list-filters--item {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.architect-filters {

    #filter3 {
      column-count: 2;
    }
  }

  &.allied-filters {

    #filter1 {
      column-count: 2;
      
      @include media-breakpoint-up(lg){
        column-count: 3;
      }
    }
  }
}

// exhibitions single page
.ex-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @include media-breakpoint-up(lg){
    grid-template-columns: repeat(3, 1fr);
  }
}

// programs content before all other content
.program-content {
  @include margin(0 0 48px);
}


// search results

.search-result {
  @include margin-bottom(32px);
  @include padding-bottom(32px);
  border-bottom: 1px solid $gray;

  &:last-of-type {
    border-bottom: 0;
  }
}