.woocommerce {

  #content div.product div.images, 
  div.product div.images {
    float: none;
    width: 50%;

    @include media-breakpoint-up(lg){
      float: left;
      width: 33%;
    }
  }

  .related.products {
    clear: both;
    display: none;
    overflow: hidden;
    height: 0;
  }

  div.product {

    p.price, span.price {
      color: $black;
      @include font-size(20px);
    }

    .product_meta {
      @extend .visually-hidden;
    }
  }

  .woocommerce ul.products li.product .price {
    color: $black;
    display: block;
    font-weight: 400;
    @include margin-bottom(8px);
    @include font-size(16px);
  }

  &:where(body:not(.woocommerce-block-theme-has-button-styles)) {
    
    #respond input#submit,
    a.button,
    button.button,
    button.button:disabled[disabled],
    input.button {
      @extend .btn;
      @extend .btn-hlw;
    }
  }

  .button.wc-backward {
    display: none;
  }
}

:where(body:not(.woocommerce-block-theme-has-button-styles)) {

  .woocommerce {

    #respond input#submit,
    a.button,
    button.button,
    button.button:disabled[disabled],
    input.button {
      @extend .btn;
      @extend .btn-hlw;
    }

    button.button.alt.disabled {
      @extend .btn;
      border: 1px solid $med-gray !important;
      background-color: $med-gray !important;
      opacity: 0.25 !important;

      &:hover {
        cursor: help !important;
      }
    }
  }
}