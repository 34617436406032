// IE 11 flexbox fix.
// TODO: Remove when fixed in bootstrap.
// https://github.com/twbs/bootstrap/issues/17310
$enable-flex: true;
@if $enable-flex {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            @for $size from 1 through $grid-columns {
                .col-#{$breakpoint}-#{$size} {
                    max-width: percentage($size / $grid-columns);
                }
            }
        }
    }
}

//
// Mixins
//

.clearfix {
  &:after {
    content:'';
    display:table;
    clear:both; 
  }
  *zoom:1;
}

@mixin clearfix() {
  &:after {
    content:'';
    display:table;
    clear:both; 
  }
  *zoom:1;
}

@mixin transition($prop_time_easing...){ // for multiple arguments as PROP1 TIME1 EASING1, PROP2 TIME2 EASING2, etc.
  -webkit-transition:$prop_time_easing;
     -moz-transition:$prop_time_easing;
      -ms-transition:$prop_time_easing;
       -o-transition:$prop_time_easing;
          transition:$prop_time_easing;
}

@mixin rounded($value){
  -webkit-border-radius:$value;
  -moz-border-radius:$value;
  border-radius:$value;
}

/*============================================================================
  Prefixer mixin for generating vendor prefixes:
    - Based on https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/addons/_prefixer.scss
    - Usage:

      // Input:
      .element {
        @include prefixer(transform, scale(1), ms webkit spec);
      }

      // Output:
      .element {
        -ms-transform:scale(1);
        -webkit-transform:scale(1);
        transform:scale(1);
      }
==============================================================================*/

@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      -webkit-#{$property}:$value;
    } @else if $prefix == moz {
      -moz-#{$property}:$value;
    } @else if $prefix == ms {
      -ms-#{$property}:$value;
    } @else if $prefix == o {
      -o-#{$property}:$value;
    } @else if $prefix == spec {
      #{$property}:$value;
    } @else  {
      @warn "Unrecognized prefix:#{$prefix}";
    }
  }
}

@mixin transform($transform) {
  @include prefixer(transform, $transform, ms webkit spec);
}

@function em($target, $context:$base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return $target / $context + 0rem;
}

// ==========================================================================
// Helper classes
// ==========================================================================

.ir {
    background-color:transparent;
    border:0;
    overflow:hidden;
    *text-indent:-9999px;
}

.ir:before {
    content:"";
    display:block;
    width:0;
    height:150%;
}

.hideme {
    display:none; // temporarily hidden -- for modal content
}

.hidden {
    display:none !important;
    visibility:hidden;
}

.visuallyhidden {
    border:0;
    clip:rect(0 0 0 0);
    height:1px;
    margin:-1px;
    overflow:hidden;
    padding:0;
    position:absolute;
    width:1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip:auto;
    height:auto;
    margin:0;
    overflow:visible;
    position:static;
    width:auto;
}

.invisible {
    visibility:hidden;
}


@include media-breakpoint-down(lg) {
  .mobile-hide {
    display:none;
  }
}

@include media-breakpoint-up(lg) {
  .desktop-hide {
    display:none;
  }
}

.dont-break-out {
  // These are technically the same, but use both
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  // This is the dangerous one in WebKit, as it breaks things wherever
  word-break: break-all;
  // Instead use this non-standard one:
  word-break: break-word;
  // Adds a hyphen where the word breaks, if supported (No Blink)
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// ==========================================================================
// Website Fonts and Icons
// ==========================================================================
@font-face {
    font-family: 'Architype';
    src: url('#{$font-path}/Architype-Bold.woff2') format('woff2'),
        url('#{$font-path}/Architype-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Architype';
    src: url('#{$font-path}/Architype-Light.woff2') format('woff2'),
        url('#{$font-path}/Architype-Light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// usage:<span class="webicon webicon-facebook"></span>
@font-face {
  font-family: '#{$icomoon-font-family}';
  src: // url('#{$font-path}/#{$icomoon-font-family}.woff2?1wi0ih') format('woff2'),
       url('#{$font-path}/#{$icomoon-font-family}.woff?1wi0ih') format('woff'),
       url('#{$font-path}/#{$icomoon-font-family}.svg?1wi0ih') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="webicon-"], [class*=" webicon-"] {
  // use !important to prevent issues with browser extensions that change fonts
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  // Better Font Rendering =========== 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}

.webicon-desktop_mac {
  &:before {
    content: $webicon-desktop_mac; 
  }
}
.webicon-desktop_windows {
  &:before {
    content: $webicon-desktop_windows; 
  }
}
.webicon-laptop_chromebook {
  &:before {
    content: $webicon-laptop_chromebook; 
  }
}
.webicon-laptop_mac {
  &:before {
    content: $webicon-laptop_mac; 
  }
}
.webicon-laptop_windows {
  &:before {
    content: $webicon-laptop_windows; 
  }
}
.webicon-location_on {
  &:before {
    content: $webicon-location_on; 
  }
}
.webicon-location_pin {
  &:before {
    content: $webicon-location_pin; 
  }
}
.webicon-phone1 {
  &:before {
    content: $webicon-phone1; 
  }
}
.webicon-phone_android {
  &:before {
    content: $webicon-phone_android; 
  }
}
.webicon-phone_enabled {
  &:before {
    content: $webicon-phone_enabled; 
  }
}
.webicon-search1 {
  &:before {
    content: $webicon-search1; 
  }
}
.webicon-mail_outline {
  &:before {
    content: $webicon-mail_outline; 
  }
}
.webicon-insert_invitation {
  &:before {
    content: $webicon-insert_invitation; 
  }
}
.webicon-keyboard_arrow_down {
  &:before {
    content: $webicon-keyboard_arrow_down; 
  }
}
.webicon-keyboard_arrow_left {
  &:before {
    content: $webicon-keyboard_arrow_left; 
  }
}
.webicon-keyboard_arrow_right {
  &:before {
    content: $webicon-keyboard_arrow_right; 
  }
}
.webicon-keyboard_arrow_up {
  &:before {
    content: $webicon-keyboard_arrow_up; 
  }
}
.webicon-close {
  &:before {
    content: $webicon-close; 
  }
}
.webicon-shopping-cart {
  &:before {
    content: $webicon-shopping-cart; 
  }
}
.webicon-pdf {
  &:before {
    content: $webicon-pdf; 
  }
}
.webicon-talk {
  &:before {
    content: $webicon-talk; 
  }
}
.webicon-camera {
  &:before {
    content: $webicon-camera; 
  }
}
.webicon-play {
  &:before {
    content: $webicon-play; 
  }
}
.webicon-houzz {
  &:before {
    content: $webicon-houzz; 
  }
}
.webicon-file-empty {
  &:before {
    content: $webicon-file-empty; 
  }
}
.webicon-files-empty {
  &:before {
    content: $webicon-files-empty; 
  }
}
.webicon-file-text2 {
  &:before {
    content: $webicon-file-text2; 
  }
}
.webicon-file-picture {
  &:before {
    content: $webicon-file-picture; 
  }
}
.webicon-shopping-cart2 {
  &:before {
    content: $webicon-shopping-cart2; 
  }
}
.webicon-phone {
  &:before {
    content: $webicon-phone; 
  }
}
.webicon-phone-hang-up {
  &:before {
    content: $webicon-phone-hang-up; 
  }
}
.webicon-display {
  &:before {
    content: $webicon-display; 
  }
}
.webicon-mobile2 {
  &:before {
    content: $webicon-mobile2; 
  }
}
.webicon-spinner {
  &:before {
    content: $webicon-spinner; 
  }
}
.webicon-spinner2 {
  &:before {
    content: $webicon-spinner2; 
  }
}
.webicon-spinner3 {
  &:before {
    content: $webicon-spinner3; 
  }
}
.webicon-spinner4 {
  &:before {
    content: $webicon-spinner4; 
  }
}
.webicon-spinner5 {
  &:before {
    content: $webicon-spinner5; 
  }
}
.webicon-spinner6 {
  &:before {
    content: $webicon-spinner6; 
  }
}
.webicon-spinner7 {
  &:before {
    content: $webicon-spinner7; 
  }
}
.webicon-spinner8 {
  &:before {
    content: $webicon-spinner8; 
  }
}
.webicon-spinner9 {
  &:before {
    content: $webicon-spinner9; 
  }
}
.webicon-spinner10 {
  &:before {
    content: $webicon-spinner10; 
  }
}
.webicon-spinner11 {
  &:before {
    content: $webicon-spinner11; 
  }
}
.webicon-search {
  &:before {
    content: $webicon-search; 
  }
}
.webicon-zoom-in {
  &:before {
    content: $webicon-zoom-in; 
  }
}
.webicon-enlarge {
  &:before {
    content: $webicon-enlarge; 
  }
}
.webicon-plus {
  &:before {
    content: $webicon-plus; 
  }
}
.webicon-minus {
  &:before {
    content: $webicon-minus; 
  }
}
.webicon-info {
  &:before {
    content: $webicon-info; 
  }
}
.webicon-cancel-circle {
  &:before {
    content: $webicon-cancel-circle; 
  }
}
.webicon-blocked {
  &:before {
    content: $webicon-blocked; 
  }
}
.webicon-cross {
  &:before {
    content: $webicon-cross; 
  }
}
.webicon-checkmark {
  &:before {
    content: $webicon-checkmark; 
  }
}
.webicon-checkmark-hollow {
  &:before {
    content: $webicon-checkmark-hollow; 
  }
}
.webicon-arrow-up2 {
  &:before {
    content: $webicon-arrow-up2; 
  }
}
.webicon-arrow-right2 {
  &:before {
    content: $webicon-arrow-right2; 
  }
}
.webicon-arrow-down2 {
  &:before {
    content: $webicon-arrow-down2; 
  }
}
.webicon-arrow-left2 {
  &:before {
    content: $webicon-arrow-left2; 
  }
}
.webicon-circle-up {
  &:before {
    content: $webicon-circle-up; 
  }
}
.webicon-circle-right {
  &:before {
    content: $webicon-circle-right; 
  }
}
.webicon-circle-down {
  &:before {
    content: $webicon-circle-down; 
  }
}
.webicon-circle-left {
  &:before {
    content: $webicon-circle-left; 
  }
}
.webicon-checkbox-checked {
  &:before {
    content: $webicon-checkbox-checked; 
  }
}
.webicon-checkbox-unchecked {
  &:before {
    content: $webicon-checkbox-unchecked; 
  }
}
.webicon-radio-checked {
  &:before {
    content: $webicon-radio-checked; 
  }
}
.webicon-radio-checked2 {
  &:before {
    content: $webicon-radio-checked2; 
  }
}
.webicon-radio-unchecked {
  &:before {
    content: $webicon-radio-unchecked; 
  }
}
.webicon-terminal {
  &:before {
    content: $webicon-terminal; 
  }
}
.webicon-share2 {
  &:before {
    content: $webicon-share2; 
  }
}
.webicon-mail {
  &:before {
    content: $webicon-mail; 
  }
}
.webicon-mail2 {
  &:before {
    content: $webicon-mail2; 
  }
}
.webicon-mail3 {
  &:before {
    content: $webicon-mail3; 
  }
}
.webicon-mail4 {
  &:before {
    content: $webicon-mail4; 
  }
}
.webicon-google {
  &:before {
    content: $webicon-google; 
  }
}
.webicon-google-boxed {
  &:before {
    content: $webicon-google-boxed; 
  }
}
.webicon-google3 {
  &:before {
    content: $webicon-google3; 
  }
}
.webicon-google-plus {
  &:before {
    content: $webicon-google-plus; 
  }
}
.webicon-google-plus2 {
  &:before {
    content: $webicon-google-plus2; 
  }
}
.webicon-google-plus3 {
  &:before {
    content: $webicon-google-plus3; 
  }
}
.webicon-facebook {
  &:before {
    content: $webicon-facebook; 
  }
}
.webicon-facebook-boxed {
  &:before {
    content: $webicon-facebook-boxed; 
  }
}
.webicon-instagram {
  &:before {
    content: $webicon-instagram; 
  }
}
.webicon-twitter {
  &:before {
    content: $webicon-twitter; 
  }
}
.webicon-vine {
  &:before {
    content: $webicon-vine; 
  }
}
.webicon-rss {
  &:before {
    content: $webicon-rss; 
  }
}
.webicon-rss-boxed {
  &:before {
    content: $webicon-rss-boxed; 
  }
}
.webicon-youtube {
  &:before {
    content: $webicon-youtube; 
  }
}
.webicon-vimeo {
  &:before {
    content: $webicon-vimeo; 
  }
}
.webicon-vimeo-boxed {
  &:before {
    content: $webicon-vimeo-boxed; 
  }
}
.webicon-flickr {
  &:before {
    content: $webicon-flickr; 
  }
}
.webicon-flickr2 {
  &:before {
    content: $webicon-flickr2; 
  }
}
.webicon-flickr-boxed {
  &:before {
    content: $webicon-flickr-boxed; 
  }
}
.webicon-flickr4 {
  &:before {
    content: $webicon-flickr4; 
  }
}
.webicon-behance {
  &:before {
    content: $webicon-behance; 
  }
}
.webicon-behance-boxed {
  &:before {
    content: $webicon-behance-boxed; 
  }
}
.webicon-dropbox {
  &:before {
    content: $webicon-dropbox; 
  }
}
.webicon-github {
  &:before {
    content: $webicon-github; 
  }
}
.webicon-basecamp {
  &:before {
    content: $webicon-basecamp; 
  }
}
.webicon-wordpress {
  &:before {
    content: $webicon-wordpress; 
  }
}
.webicon-tumblr {
  &:before {
    content: $webicon-tumblr; 
  }
}
.webicon-tumblr-boxed {
  &:before {
    content: $webicon-tumblr-boxed; 
  }
}
.webicon-tux {
  &:before {
    content: $webicon-tux; 
  }
}
.webicon-apple {
  &:before {
    content: $webicon-apple; 
  }
}
.webicon-finder {
  &:before {
    content: $webicon-finder; 
  }
}
.webicon-android {
  &:before {
    content: $webicon-android; 
  }
}
.webicon-reddit {
  &:before {
    content: $webicon-reddit; 
  }
}
.webicon-linkedin-boxed {
  &:before {
    content: $webicon-linkedin-boxed; 
  }
}
.webicon-linkedin {
  &:before {
    content: $webicon-linkedin; 
  }
}
.webicon-lastfm {
  &:before {
    content: $webicon-lastfm; 
  }
}
.webicon-lastfm-boxed {
  &:before {
    content: $webicon-lastfm-boxed; 
  }
}
.webicon-delicious {
  &:before {
    content: $webicon-delicious; 
  }
}
.webicon-pinterest {
  &:before {
    content: $webicon-pinterest; 
  }
}
.webicon-pinterest2 {
  &:before {
    content: $webicon-pinterest2; 
  }
}
.webicon-yelp {
  &:before {
    content: $webicon-yelp; 
  }
}
.webicon-paypal {
  &:before {
    content: $webicon-paypal; 
  }
}
.webicon-file-pdf {
  &:before {
    content: $webicon-file-pdf; 
  }
}
.webicon-file-openoffice {
  &:before {
    content: $webicon-file-openoffice; 
  }
}
.webicon-file-word {
  &:before {
    content: $webicon-file-word; 
  }
}
.webicon-file-excel {
  &:before {
    content: $webicon-file-excel; 
  }
}
.webicon-libreoffice {
  &:before {
    content: $webicon-libreoffice; 
  }
}
.webicon-up {
  &:before {
    content: $webicon-up; 
  }
}
.webicon-info2 {
  &:before {
    content: $webicon-info2; 
  }
}
.webicon-peace {
  &:before {
    content: $webicon-peace; 
  }
}
.webicon-snapchat {
  &:before {
    content: $webicon-snapchat; 
  }
}

// ==========================================================================
//   Base
// ==========================================================================
html, body {
  width: -webkit-calc(100% - 0px);
  border: unset !important;
  outline: unset;
}

html {
  font-size: $base-font-size; // base font size = 16px
  position: relative;
  min-height: 100%;
  -webkit-text-size-adjust: 100%; 
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar; // IE scrollbar hack
  
  @media (min-width:1921px ){
    @include font-size(20px); // base font size = 16px
  }
}

body {
  font-family: $font-stack;
  font-size: 100%; // base font size = 16px
  margin: 0; 
  padding: 0; 
  color: $black;
  overflow-x: hidden;
  
  @media (min-width:1921px ){
    font-size: 150%; 
  }
}

body.page {
  overflow-y: auto;
}

#tinymce {
  background-color: #fff;
  background-image: none;
}

body, button, input, select, textarea {
  line-height: 1.618; 
}

.wrap, .container {
  position: relative;
}

// sticky footer 
// new method
// html, body { height: 100%; }
// body > footer.content-info { position: sticky; top: 100vh; }

// old method
body .wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

header.banner,
footer.content-info {
    flex-shrink: 0;
}

.wrap {
    flex-grow: 1;
}
// END sticky footer

// .sidebar-primary .main {
//   padding-left:0;
//   padding-right:0;
  
//   @include media-breakpoint-up(sm) {
//     -webkit-box-flex: 0;
//     -ms-flex: 0 0 100% !important;
//     flex: 0 0 100% !important;
//     max-width: 100% !important;
//   }
// }

/* ==================================================
   Code and Pre
   ================================================== */
code, pre {
  padding: 0 3px 2px;
  color: #232527;
  @include font-size(12px);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  -webkit-border-radius: 3px;
       -moz-border-radius: 3px; 
          border-radius: 3px;
}

code {
  padding:2px 4px; 
  border:1px solid #dde4e9; 
  background-color:#f6f6f6; 
  color:#232527;
  white-space:nowrap;
}

pre {
    display:block;
    margin:30px;
    padding:15px;
    border:1px solid #dde4e9;
    -webkit-border-radius:4px;
       -moz-border-radius:4px;
            border-radius:4px;
    background-color:#fcfcfc;
    white-space:pre;
    white-space:pre-wrap;
    word-wrap:break-word;
    word-break:break-all;
    font-size:12px;
    line-height:20px;
}

pre code {
    padding:0;
    border:0;
    background-color:transparent;
    color:inherit;
    white-space:pre;
    white-space:pre-wrap;
}

.pre-scrollable {
    overflow-y:scroll;
    max-height:340px;
}

.badge, .dropdown-header, .dropdown-menu>li>a, .input-group-addon, .input-group-btn, .label, .text-nowrap, code {
    white-space:normal;
}

object, iframe {
  max-width:100%;
  // height:auto;
}

/* ==================================================
   Typography
   ================================================== */
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 25px 0; 
  font-weight: 700; 
  font-family: $sans-serif;
  line-height: 1.1;
  color: $black;
  
  a, a:hover {
    text-decoration: none;
  }
}

.page-header {

  h1 {
    @include margin(48px 0);
    @include padding-bottom(24px);
    @include font-size(28px);
    font-weight: 700;
    // color: $black;
    text-align: left;
    // text-transform: uppercase;
    border-bottom: 3px solid $black;
  }

  small {
    @include font-size(20px);
    font-weight: 400;
  }
}

h2 {
  @include font-size(36px);
}

h2.entry-title {
  padding: 10px 15px 15px;
  @include font-size(20px);
  
  @include media-breakpoint-up(lg){
    padding: 10px 15px 15px;
    @include font-size(24px);
  }
}

h2.search-result-title {
  padding: 0;
  @include font-size(24px);
}

h2.subhead {
  @include font-size(36px);
  font-weight: 700;
}

h3 {
  margin-bottom: 0;
  @include font-size(32px);

  &.header-underlined {
    @include margin-bottom(16px);
    @include padding-bottom(16px);
    @include font-size(16px);
    line-height: 1.1;
    text-transform: uppercase;
    border-bottom: 1px solid $gray;
  }

  &.section-header-underlined {
    @include margin-bottom(16px);
    @include padding-bottom(16px);
    @include font-size(18px);
    line-height: 1.1;
    text-transform: uppercase;
    border-bottom: 3px solid $black;
  }
}

h4 {
  @include font-size(25px);
}

h5 {
  @include font-size(22px);
}

h6 {
  @include font-size(15px);
  color: $gray;

}

small {
  @include font-size(13px);
}

p {
  margin: 0 0 25px;
  
  &:empty {
    display: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  & + ul, & + ol {
    // margin-top: -25px; // WHY?!?
  }
}

.sidebar-content p {
  line-height: 1.4;
}

.red-text { color: $red; }
.black-text { color: $black; }
.white-text { color: $white; }

.nolist {
  list-style: none;
  margin: 0;
  padding: 0;
  
  li {
    margin: 0;
    padding: 0;
    text-indent: 0;

    &::before {
      content: "";
    }
  }
}

ul.link-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    margin: 0 0 5px;
    padding: 0 20px 5px 0;
    text-indent: 0;
    border-bottom: 1px solid $med-gray;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &::after {
      position: absolute;
      // top: 50%;
      right: 0;
      // transform: translateX(-10px);
      
      content: "\003e";
      color: $red;
      font-weight: 700;
      font-size: 24px;
      line-height: 1;
    }

    /* &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    } */

    a {
      width: 100%;
      display: block;
      color: $black;

      &:hover {
        color: $red;
      }
    }
  }
}

@include media-breakpoint-up(lg){

  // columnize lists
  div, ul {
    &.make-2-cols {
      columns: 2 auto;
      
      li {
        margin-right: 15px;
      }
    }

    &.make-3-cols {
      columns: 2 auto;

      li {
        margin-right: 15px;
      }
    }
  }
}

hr {
  margin: 25px 0;
  border: 0;
  width: auto;
  height: 1px;
  clear: both;
  background-color: $med-gray;
}

hr.white {
  margin: 0 25px;
  border: 0;
  width: auto;
  height: 1px;
  clear: both;
  background-color: $white;
}

hr.hr-med,
hr.hr-medium {
  margin: 15px 0;
}

hr.hr-sm,
hr.hr-small {
  margin: 5px 0;
}

hr.hr-clear {
  // border-top-color:transparent;
  background-color: transparent;
}

// p + hr {
//   margin-top: 0;
//   margin-bottom: 0;
// }

.rwd-break { 
  display: none;
  
  @include media-breakpoint-up(md) {
    display: block;
  } 
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    @include font-size(18px);
    font-weight: 700;
    border-left: 5px solid $light-gray;
}

/* ==================================================
   Images & Video/Iframe
   ================================================== */
img {
  border: 0;
  outline: none;
}

.wrap figure,
.wrap img {
  max-width: 100%;
  height: auto;
}

.full-width-img {
  width: 100%;
  height: auto;
}

.img-overlay {
  background-color: $gray;
  
  img {
    opacity: 0.3;
  }
}

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  border-color: $link-color;
}

.hover-effect{
    display: block;
    overflow: hidden;
  
  a {
    display: block;
    overflow: hidden;
  }

  img {
    @include transition(opacity 0.25s ease-in-out, transform 0.25s ease-in-out);
    @include transform(scale(1));
  }

  &:hover img {
    @include transform(scale(1.03));
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.img-holder {
  img {
    width: 100%;
    height: 100%; 
    object-position: 50% 50%;
    object-fit: cover;
  }
}

.img-attr {
  position: relative;

  .inline-credit {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $black;
    padding: 0 3px;
    height: 18px;

    &, * {
      color: $white;
      font-size: 10px;
      line-height: 18px;
    }
  }
}


/* ==================================================
   Links
   ================================================== */
a { 
  outline: none; 
  border: 0;
  color: $red;
  // font-weight: 700;
  text-decoration: none;
  @include transition(all .5s ease);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    color: $gray;
    // text-decoration: underline;
  }
}

li.menu-item > a {
  text-decoration: none;
}

.back-to-top {

  margin: 0 auto;
  padding: 5px 0;
  @include font-size(21px);
  font-weight: 700;
  text-align: center;
  color: $black;
  background-color: $white;
  
  a {
    color: $black;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    
    &:hover {
      // color: $white;
    }
  }
}

.social-icons {
  list-style: none;
  margin: 0 0 25px;
  padding: 0;
  display: inline-flex;
  justify-content: center;

  @include media-breakpoint-up(lg){
    justify-content: flex-start;
  }

  li {
    padding: 5px 25px 0 0;

    a {
      @include transition(all 0.5s ease);

      .webicon {
        color: $red;
        @include font-size(24px);
        @include transition(all 0.5s ease);
      }

      &:hover {
        .webicon {
          color: $black;
        }
      }
    }
  }
}

