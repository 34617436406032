// global

.page-banner {
  @include margin-bottom(32px);
  position: relative;

  h1 {
    position: absolute;
    top: 50%;
    width: 100%;
    @include font-size(56px);
    text-align: center;
    color: $white;
    text-transform: uppercase;
    text-shadow: 0 0 10px rgba($black, 0.4);
  }
}

section {

  &.home-section {
    @include padding(64px 0);
  }
}

.floated-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: unset;
  height: auto;
  background-color: rgba($black, 0.7);
  @include padding(24px);
}

// home page
#home_carousel {
  @include margin-bottom(48px);
}

.slide-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  position: relative;

  @include media-breakpoint-up(lg){
    grid-template-columns: 2fr 1fr;
  }

  .carousel-item--image {

    img {
      object-fit: cover;
    }
    
  }

  .carousel-item--content {
    @include padding(40px);
    background-color: $black;
    color: $white;

    h2, 
    h3, 
    p {
      color: $white;
    }

    h4 {
      color: $med-gray;
    }

    h2.content-title {
      @include margin-bottom(32px);
    }

    h3.content-info-1,
    h3.content-info-2 {
      margin-bottom: 5px;
      @include font-size(22px);
    }

    h3.content-info-2 {
      @include margin-bottom(32px);
      font-weight: 700;
    }

    h4.content-category {
      margin-bottom: 5px;
      @include font-size(18px);
      text-transform: uppercase;
    }

    p {
      @include font-size(20px);
      font-weight: 400;
    }
  }
}

.carousel-indicators {
  margin:0 15%;
  bottom: -20px;
  
  @include media-breakpoint-up(lg){
    bottom: -40px;
  }

  [data-bs-target] {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-left: 10px;
    text-indent: -999px;
    cursor: pointer;
    background-color: $med-gray;
    border: 1px solid $gray;
    border-radius: 0;
    @include transition(all .2s ease);
  }
}

.featured-stories--item {
  position: relative;
}

.featured-stories--content {
  
  h2,
  h4 {
    color: $white;
  }
  
  h2 {
    @include font-size(32px);
  }
  
  h4 {
    margin-bottom: 0;
    @include font-size(20px);
    font-weight: 400;
  }
}

// homepage dynamic content section
.hp-wide--section,
.hp-side--section {
  @include margin-bottom(48px);
}

// shadowed-boxes, used for upcoming events, architects, allied members, etc.
.ue-grid {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr; // DO NOT USE PERCENTAGES!!! https://stackoverflow.com/questions/48214281/why-does-grid-gap-cause-an-overflow
  gap: 32px;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ue-grid-3 {
  
  grid-template-columns: [col] 1fr;
  grid-template-rows: [row] auto;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, [col] minmax(0, 1fr));
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: [col] repeat(4, minmax(0, 1fr));
    grid-template-rows: [row] minmax(250px, auto);
  }
}

.ue-grid-4 {
  grid-template-columns: [col] 1fr;
  grid-template-rows: [row] auto;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, [col] minmax(0, 1fr));
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: [col] repeat(5, minmax(0, 1fr));
    grid-template-rows: [row] minmax(250px, auto);
  }

  .ad-block {
    grid-column: col;
    grid-row: row 3;

    @include media-breakpoint-up(md) {
      grid-column: span 2;
    }

    @include media-breakpoint-up(lg) {
      grid-column: span 3;
    }

    @include media-breakpoint-up(xl) {
      grid-column: 1 / 6;
    }
  }
}

.ue-item {
  @include margin(0 0 30px);
  min-height: 100%;
  display: flex;
  flex-direction: column; 
  box-shadow: 0 0 10px rgba($black,0.4);
  position: relative;
  @include transition(all .5s ease);

  &:hover {
    // @include margin(5px);
    box-shadow: 0 0 2px rgba($black,0.4);
  }

  .ue-item--content {
    flex-grow: 1;
    @include padding(24px 24px 0);

    time {
      color: $gray;
    }

    h4 {
      @include font-size(24px);
    }
  }

  .ue-item--photo {
    flex-shrink: 0;
  }

  // allied members
  &.allied-member {

    header {
      @include margin-bottom(32px);

      h4 {
        margin-bottom: 0;

        & + p {
          margin-top: 5px;
        }
      }
      
      p {
        color: $black;
      }
    }
  }
}

// posts show in archives and member pages, eg. committees
.posts-row {

  .posts-row--item {
    @include margin-bottom(32px);

    h6 {

      a {
        color: $black;

        &:hover {
          color: $red;
        }
      }
    }
  }
}

.bio-photos {
  @include margin-top(32px);  

  .bio-photos--item {
    @include margin-bottom(32px);
  }


  h5 {
    @include margin(10px 0 0);
    @include font-size(18px);
  }

  h6 {
    margin-bottom: 0;
    @include font-size(15px);
    color: $med-gray;
  } 
}

// ads stuff

.ad-section {
  text-align: center;
}

.ad-block {
  border-top: 1px solid $light-gray;
  @include padding(48px 0);
  @include margin(48px 0);
  text-align: center;
}