// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

// 1/5 grid iteam
.col-15, 
.col-md-15,
.col-lg-15,
.col-xl-15 {

  @include media-breakpoint-up(md){
    flex: 0 0 auto;
    width: 20%;
    max-width: 20%;
  }
}
