// Search form
.search-form {
  // @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  // @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// bootstrap overrides
.form-select {
  display: block;
  width: 100%;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
  -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
  font-family: $font-stack;
  @include font-size($form-select-font-size);
  font-weight: 700;
  // line-height: $form-select-line-height;
  color: $red;
  // background-color: $form-select-bg;
  background-image: escape-svg($form-select-indicator);
  // background-repeat: no-repeat;
  // background-position: $form-select-bg-position;
  // background-size: $form-select-bg-size;
  border: 1px solid $gray;
  border-width: 1px 0;
  border-radius: 0;
  
  // @include border-radius($form-select-border-radius, 0);
  // @include box-shadow($form-select-box-shadow);
  // @include transition($form-select-transition);
  
  // appearance: none;

  &:focus {
    border-color: $gray;
    outline: 0;
    // @if $enable-shadows {
    //   @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow);
    // } @else {
    //   // Avoid using mixin so we can pass custom focus shadow properly
    //   box-shadow: $form-select-focus-box-shadow;
    // }
    box-shadow: unset;
  }

  option {
    color: $black;
    line-height: 1.4;
  }
}

.form-select-lg {
  // padding-top: $form-select-padding-y-lg;
  // padding-bottom: $form-select-padding-y-lg;
  // padding-left: $form-select-padding-x-lg;
  @include font-size(18px);
  border-radius: 0;
}


.comment-form input:focus[type=email], 
.comment-form input:focus[type=text], 
.comment-form input:focus[type=url], 
.comment-form textarea:focus, 
.form-control:focus, 
.input-text:focus, 
.search-form .search-field:focus {
  color: $black;
  background-color: #fff;
  border-color: $black;
  outline: 0;
  box-shadow: unset;
}


// contact form 7 fixes
.wpcf7-list-item {
  @extend .form-check;

  input[type="radio"] {
    @extend .form-check-input;
  }
}
