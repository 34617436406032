// jobs table
.jobs-row ul {
  list-style: none;
  margin: 0 0 25px;
  padding: 0 0 25px;
  display: grid;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: 1fr;
  gap: 5px;
  border-bottom: 1px solid $med-gray;

  @include media-breakpoint-up(lg){
    grid-template-columns: 2fr 2fr 1fr;
    gap: 15px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  li:nth-of-type(1){
    font-weight: 700;

    @include media-breakpoint-up(lg){
      font-weight: 400;
    }
  }

  li:nth-of-type(3){
    display: flex;
    justify-content: flex-end;
  }
}