// archive page
.filter-bar {
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @include media-breakpoint-up(lg){
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .filter-toggle {
    position: relative;
    z-index: 99;
  }

  .filter-toggle--button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    font-weight: 700;

    &.active {
      color: $red;
    }

    &:hover {
      cursor: pointer;
      color: red;
    }

    &::after {
      position: absolute;
      right: 1rem;
      color: $black;
      font-weight: 700;
      font-size: 24px;
      line-height: 13px;
      content: '\2304';
    }
  }

  #filter {
    display: none; // init state
    margin-top: 5px;
    padding: 10px;
    position: absolute;
    z-index: 100;
    // top: 5px;
    background-color: $white;
    width: auto;
    box-shadow: 0 0 10px rgba($black,0.4);

    h4 {
      margin-bottom: 5px;
      @include font-size(20px);
    }
  }
}

// single architects page
h5.sidebar-arch--title {
  margin-bottom: 5px;
  font-weight: 400;
  @include font-size(14px);
  color: $med-gray;
}

.sidebar-arch--info {
  line-height: 1.4;

  a {
    font-weight: 700;
  }
}
h5.sidebar-arch--title,
.sidebar-arch--info {
  &.inline {
    display: inline-block;   
  }
}

.main-arch--photos {
  margin: 0 auto;
  text-align: center;

  & + .main-arch--content {
    @include margin-top(32px);
  }

  .main-arch--photos-primary {
    background-color: $light-gray;

    .carousel-item {
      width: 100%;
      // height: 66%;
      height: calc( 66% + 0px );
    }

    .carousel-content {
      width: 100%;
      height: calc( 66vh + 0px );

      img {
        width: 100%!important;
        height: 100%!important;
        object-fit: contain;
        object-position: center center;
      }
    }
  }

  .main-arch--photos-thumbnails {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    left: 0;
    right: 0;
    bottom:-100px;
    background-color: $light-gray;

    display: flex;
    justify-content: center;
    align-items: center;
    
    // [data-bs-target] {
    button {
      width: 15%;
      // height: 100px;
      margin-right: 10px;
      margin-left: 10px;
      text-indent: 0;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      border-radius: unset;
      // transition: all .2s ease;

      img {
        // width: auto!important;
        // height: 100px!important;

        width: 100%;
        height: 100%;
        object-position: 50% 50%;
        object-fit: cover;
      }
    }
  }
}

//
.carousel-item {
  background-color: $light-gray;
  width: 100%;
  // height: 60%;

  @include media-breakpoint-up(lg){
    // height: 70vh;
  }

  img {
    width: 100%;
    // max-height: 70vh;
    object-fit: contain;
    object-position: 50% 50%;
  }
}

.single-architects {

  .carousel-item {
    // background-color: $light-gray;
    // width: 100%;
    height: 60%;

    @include media-breakpoint-up(lg){
      height: 70vh;
    }

    img {
      // width: 100%;
      max-height: 70vh;
      // object-fit: contain;
      // object-position: 50% 50%;
    }
  }
}

.proj-nav {
  @include margin-bottom(48px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;

  .proj-nav-item a {
    padding: 5px;
    display: block;
    @include font-size(12px);
    line-height: 1;
    // color: $light-blue;
    // border: 1px solid $light-blue;

    &:hover {
      // color: $blue;
      // border-color: $blue;
      text-decoration: none;
    }
  }
}