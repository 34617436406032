.btn {
    position: relative;
    display: inline-block;
    padding: $x_padding 3rem $x_padding 1.5rem;
    @include font-size(16px);
    text-align: center;
    font-family: $sans-serif;
    font-weight: 700;
    letter-spacing: 0.1rem;
    text-decoration: none !important;
    color: $white;
    border: 1px solid $white !important;
    border-radius: 0;
    text-transform: uppercase;
    @include transition(all 0.5s ease);

    &:focus,
    &.focus,
    &:visited,
    &.visited {
        box-shadow: unset;
    }

    &::after {
      position: absolute;
      right: 1rem;  
      content: "\003e";
      color: $white;
      font-weight: 700;
      font-size: 24px;
      line-height: 1;
      @include transition(all 0.5s ease);
    }
}

.btn-rnd {
  border-radius: calc(1.5em + 2rem + 2px / 2);
}

.btn:hover, .btn:active, .btn:visited {
    color: $white;
    text-decoration: none !important;
}

.btn-sm {
    padding: calc(#{$x_padding} - 3px) 2rem calc(#{$x_padding} - 3px) 0.75rem;
    height: auto;
    font-size: em(10px);

    &::after {
        right: 0.75rem;
        font-size: 16px;
    }
}

.btn-lg {
    @include font-size(22px);
}

.btn-block {
    padding-right: 2rem;
    display: block;
    text-align: left;
    width: 100%;
    white-space: wrap;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &::after {
      position: absolute;
      right: 1rem;
      content: "\003e";
      color: $white;
      font-weight: 700;
      font-size: 24px;
      line-height: 1;
    }
}

.btn-serif {
    @include font-size(24px); // bump font size for serifs
    font-family: $serif;
    font-weight: 400;
}

.btn-default { 
    border-color: $brand-primary; 
    background-color: $brand-primary;
    color: $white;
}
.btn-dark, .btn-dark:focus { 
    background-color: $black !important; 
    border-color: $black !important; 
    color: $white !important; 
}
.btn-red, .btn-red:focus { 
    background-color: $red !important; 
    border-color: $red !important; 
    color: $white !important; 
}
.btn-hlw, .btn-hlw:focus,
.btn-hlw-light, .btn-hlw-light:focus { 
    border-width: 1px !important; 
    background-color: transparent !important; 
    border-color:$black !important; 
    color:$black !important; 

    &::after {
        color: $black;
    }
}
.btn-hlw-dark, .btn-hlw-dark:focus { 
    border-width: 1px !important; 
    background-color: transparent !important; 
    border-color: $white !important; 
    color: $white !important; 
}
.btn-light, .btn-light:focus,
.btn-white, .btn-white:focus { 
    background-color: $light-gray !important; 
    border-color: $light-gray !important; 
    color: $black !important;

    &::after {
        color: $black;
    } 
}

.btn:hover, .btn:active, .btn.active,
.btn-default:hover, .btn-default:active, .btn-default.active {
    background-color: $black !important; 
    border-color: $black !important; 
    color: $white !important; 
    outline: 0;
    box-shadow: unset;
}

.btn-light:hover, .btn-light:active,
.btn-white:hover, .btn-white:active,
.btn-red:hover, .btn-red:active, .btn-red.active, 
.btn-orange:hover, .btn-orange:active, .btn-orange.active, 
.btn-dark:hover, .btn-dark:active, .btn-dark.active, 
.btn-hlw:hover, .btn-hlw:active, .btn-hlw.active, .btn-hlw.is-checked,
.btn-hlw-orange:hover, .btn-hlw-orange:active, .btn-hlw-orange.active, .btn-hlw-orange.is-checked,
.btn-hlw-light:hover, .btn-hlw-light:active, .btn-hlw-light.active, .btn-hlw-light.is-checked,
.btn-hlw-dark:hover, .btn-hlw-dark:active, .btn-hlw-dark.active, .btn-hlw-dark.is-checked {
    background-color: $gray !important; 
    border-color: $gray !important; 
    color: $white !important; outline: 0;
    box-shadow: unset;

    &::after {
        color: $white;
    }
}

// wooverides
.button {
    @extend .btn;
    @extend .btn-hlw;
}

.input-text {
    @extend .form-control;
}

// form elements
$form-check-input-bg:                     $red;
$form-check-input-border:                 1px solid rgba($black, .25);
$form-check-input-border-radius:          .25em;
$form-check-radio-border-radius:          50%;
$form-check-input-focus-border:           unset;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow;
