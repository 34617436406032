.tribe-common {

  a {
    color: $black;

    &:hover {
      color: $red;
    }
  }

  // Month name header
  .tribe-events-c-top-bar__datepicker-button {
    // align-items: center;
    // display: flex;
    // flex: none;

    border: 0;
    outline: 0;
    background-color: transparent;
  }
  
  // days of week
  .tribe-events-calendar-month__header-column {
    border-top: 3px solid $black;
    padding-top: 10px;
    padding-bottom: 0;
    // width: var(--tec-grid-width-1-of-7);
  }

  .tribe-events-calendar-month__header-column-title {
    @include font-size(24px);
    font-weight: 400;
    text-transform: uppercase;
  }

  // event title
  .tribe-events-calendar-month__calendar-event-title,
  .tribe-events-calendar-month__multiday-event-bar-title {
    font-weight: 600;
    @include font-size(16px);
  }

  .tribe-common-l-container {
    margin: 0;
    padding: 0;
    max-width: unset;
    width: 100%;
  }

  &.tribe-common--breakpoint-medium {
    
    .tribe-common-l-container {
      padding: 0;
    }

    .tribe-events-calendar-month__day-date {
      padding: 0.5rem 0;
      @include font-size(24px);
    }

    .tribe-events-calendar-month__day--other-month {
      h3 {
          color: rgba($black,0.2);

        a {
          color: rgba($black,0.2);

          &.tribe-events-calendar-month__calendar-event-title-link {
            color: $black;
          }
        }
      }
    }

    .tribe-events-calendar-month__day {
      // aspect-ratio: auto;
      border-color: $black;
      // border-style: solid;
      border-width: 0 0 1px 0;
      // min-height: 168px;
      // overflow: visible;
      // padding-top: 0;

      &.tribe-events-calendar-month__day--current {
        box-shadow: inset 0 -8px 0 $red;
      }
    }

    .tribe-events-calendar-month__calendar-event {
      margin: 0 0 5px;
      padding: 0 10px 0 0;
      position: relative;

      &:last-of-type {
        margin-bottom: 24px;
      }
    }
  }
}

.tooltipster-base {

  &.tribe-events-tooltip-theme {
    // height: auto!important;
    max-width: 300px;
    // padding: var(--tec-spacer-5);

    .tooltipster-box {
      margin: 0;

      .tooltipster-content {
        padding: 0;
        word-break: break-word;
      }
    }
  }

  &.tooltipster-sidetip {

    .tooltipster-box {

      background: $white;
      border: 1px solid $light-gray;
      border-radius: 0;
      box-shadow: 0 0 10px rgba($black,0.4);
      
      .tooltipster-content {
        color: $black;
        line-height: 1.4;
        padding: 10px;

        h3.tribe-events-calendar-month__calendar-event-tooltip-title {
          @include font-size(20px);
        }
      }
    }
  }
}